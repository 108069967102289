<template>

	<div v-if="loading">
		<div class="flex justify-center">
			<span class="loading loading-spinner loading-md"></span>
		</div>
	</div>

	<template v-if="!loading">

		<p v-if="activities.length === 0" class="italic">{{ $t('No activity was recorded during this period') }}</p>

		<div v-else>

			<div class="lg:flex gap-10">

				<table id="activity-chart">
					<thead>
						<tr>
							<td class="px-4 pb-4 text-xs uppercase" valign="top"></td>
							<td class="px-4 flex-col items-center gap-8 pb-4" valign="top">
								<span class="text-xs uppercase">{{ $t('Time spent') }}</span>
								<div class="flex gap-4 text-sm py-4">
									<label class="border-accent" :class="[showAsAvgTime? '' : 'border-b-2']" @click="showAsAvgTime = false">
										{{ $t('Total time') }}
									</label>
									<input type="checkbox" v-model="showAsAvgTime" class="toggle toggle-xs bg-neutral" @change="onAverageToggleChange" />
									<label class="border-accent" :class="[!showAsAvgTime? '' : 'border-b-2']" @click="showAsAvgTime = true">
										{{ $t('Average per session') }}
									</label>
									<div class="tooltip tooltip-info tooltip-bottom hover:cursor-help" :data-tip="$t('#info: what is an activity session')">
										<InformationCircleIcon class="w-5 h-5 hover:cursor-pointer text-base-content-light" />
									</div>
								</div>
							</td>
						</tr>
					</thead>
					<tbody>
						<template v-for="(activity, activityIndex) in activities" :key="activityIndex">
							<tr class="hover:bg-base-100 transition-colors" :id="'act-index-' + activityIndex">
								<td class="px-2 py-1.5 flex gap-2 items-center max-w-72">
									<ActivitySymbol :activity="activity.activity" class="w-6 bg-base-100 rounded-lg shrink-0" />
									<span :title="activity.activity.name" class="overflow-hidden text-ellipsis whitespace-nowrap">{{ activity.activity.name }}</span>
								</td>
								<td class="px-4 py-1.5">
									<div class="flex items-center">
										<template v-if="showAsAvgTime">
											<div class="h-4 shrink-0" :style="'width: ' + calcBarWidth(percentageOfAverages(activity)) + 'px;'">
												<div class="h-4 flex bg-green-400 rounded-md overflow-hidden growing-bar"></div>
											</div>
											<div class="bg-info text-info-content text-sm px-1 py-0.5 rounded shrink-0">{{ readableDuration(activityAverage(activity)) }}</div>
										</template>
										<template v-else>
											<div class="h-4 tooltip hover:cursor-help" :data-tip="readableDuration(activity.totalTimeSpent) + ' - ' + $tc('{sessions} sessions', activity.sessions, { sessions: activity.sessions} )" :style="'width: ' + calcBarWidth(percentageOfAllTimeSpent(activity.totalTimeSpent)) + 'px;'">
												<div class="h-4 flex bg-green-400 rounded-md overflow-hidden growing-bar"></div>
											</div>
											<div class="pct-label bg-info text-info-content text-sm px-1 py-0.5 rounded shrink-0">{{ percentageOfAllTimeSpent(activity.totalTimeSpent) + '%' }}</div>
										</template>
									</div>
								</td>
							</tr>
						</template>
					</tbody>
				</table>

				<div>
					<div v-if="showAsAvgTime && filter.pupilId" class="stats stats-vertical mt-24">
						<!-- <div class="stat">
							<div class="stat-title">{{ $t('Total time') }}</div>
							<div class="stat-value">{{ readableDuration(totalTimeSpent, true) }}</div>
						</div>
						<div class="stat" v-if="filter.pupilId" :title="$t('Total time spent / amount of days with any activity')">
							<div class="stat-title">{{ $t('Avg time / Day') }}</div>
							<div class="stat-value">{{ readableDuration(averageTimePerDay, true) }}</div>
						</div> -->
						<div class="stat">
							<div class="stat-title leading-3">{{ $t('Avg time / session') }}<br><span class="lowercase text-xs">{{ $t('Across all activities') }}</span></div>
							<div class="stat-value">{{ readableDuration(averageTimePerSession, true) }}</div>
						</div>
					</div>

					<div v-if="!showAsAvgTime" class="mt-24 hidden lg:block w-56">
						<ProportionChart :data="datasetForProportionChart" :options="pieChartOptions" />
					</div>
				</div>

			</div>

	</div>

</template>

</template>

<style>
.growing-bar {
	animation-name: grow;
	animation-duration: .5s;
	animation-timing-function: ease-out;
	animation-iteration-count: 1;
}
@keyframes grow {
	0% { width: 0%; }
  	100% { width: 100%; }
}
tr.highlighted-row {
	@apply bg-base-100;
}
tr.highlighted-row .pct-label {
	@apply font-bold;
	transform-origin: center left;
	transform: scale(1.2);
}
</style>

<script lang="ts">
import { mapState, mapStores } from "pinia";
import {InsightsFilter, useInsightsStore} from "@/stores/Insights.store";
import { usePupilsStore } from "@/stores/Pupils.store";
import {Pupil} from "@/models/Pupil.model";
import { Duration, Info } from "luxon";
import ActivitySymbol from "@/components/ui/symbols/ActivitySymbol.component.vue";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";


import { Chart as ChartJS, Legend, PieController, ArcElement, Tooltip } from 'chart.js';
import { Doughnut as ProportionChart } from 'vue-chartjs';
ChartJS.register(Legend, Tooltip, ArcElement);



let hightlightedRows = { fromIndex: null, toIndex: null };
const hightlightCSSClass = 'highlighted-row';

// Highlight one or more activities in the chart when hovering over the corresponding pie chart / doughnut section
function hightlightActivity(fromIndex, toIndex = null) {
	if(fromIndex != hightlightedRows.fromIndex || toIndex != hightlightedRows.toIndex) {
		clearActivityHighlighting();
	}
	if(fromIndex !== null) {
		hightlightedRows.fromIndex = fromIndex;
		hightlightedRows.toIndex = toIndex;
		if(fromIndex === toIndex) {
			const row = document.getElementById('act-index-' + fromIndex);
			row.classList.add(hightlightCSSClass);
		} else {
			const rows = document.querySelectorAll('#activity-chart > tbody > tr');
			const rowsToHighlight = [];
			// loop over the rows, skip the rows until a row with an id that matches the fromIndex is found, then highlight all rows
			// until a row with an id that matches the toIndex is found
			for (let i = 0; i < rows.length; i++) {
				const row = rows[i];
				if (row.id === 'act-index-' + fromIndex) {
					rowsToHighlight.push(row);
				}
				if (rowsToHighlight.length > 0 && row.id === 'act-index-' + toIndex) {
					rowsToHighlight.push(row);
					break;
				}
				if (rowsToHighlight.length > 0) {
					rowsToHighlight.push(row);
				}
			}
			// set styling for all rows in the range
			rowsToHighlight.forEach((row) => {
				row.classList.add(hightlightCSSClass);
			});
		}
	}
}

function clearActivityHighlighting() {
	document.querySelectorAll('#activity-chart > tbody > tr.' + hightlightCSSClass).forEach((el) => {
		el.classList.remove(hightlightCSSClass);
	});
}



export default {


	props: {
		pupil: {
			type: Pupil,
			required: false
		},

		filter: {
			type: InsightsFilter,
			required: true
		}
	},

	components: {
		ActivitySymbol,
		InformationCircleIcon,
		ProportionChart,
	},

	data() {
		return {
			maxBarWidth: 300,
			loading: false,
			showAsAvgTime: false,
			pieChartOptions: {
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						enabled: false,
						external: function(context) {
							const tooltipModel = context.tooltip;

							if (tooltipModel.opacity === 0) {
								hightlightActivity(null);
							} else if(tooltipModel.opacity === 1) {
								console.log(tooltipModel.dataPoints[0].dataset);
								if(tooltipModel.dataPoints[0].dataIndex === tooltipModel.dataPoints[0].dataset.data.length - 1) {
									hightlightActivity(tooltipModel.dataPoints[0].dataIndex);
								} else {
									hightlightActivity(tooltipModel.dataPoints[0].dataIndex, tooltipModel.dataPoints[0].dataIndex);
								}
							}
						}
					}
				}
			}
		}
	},

	computed: {
		...mapStores(useInsightsStore, usePupilsStore),
		...mapState(useInsightsStore, [
			'activities',
			'maxTotalTimeSpentInActivity',
			'maxAverageDurationInActivity',
			'totalTimeSpent',
			'totalAverages',
			'totalSessions',
			'activitiesDaysActiveCount'
		]),

		averageTimePerSession() {
			return this.totalTimeSpent / Math.max(1, this.totalSessions);
		},

		averageTimePerDay() {
			return this.totalTimeSpent / Math.max(1, this.activitiesDaysActiveCount);
		},

		// method that returns the dataset for the chartjs pie chart, merging all activities with less than 5% of the total time spent
		datasetForProportionChart() {
			const bgColorsRed = ['#dc2626','#ef4444','#f87171','#fca5a5'];
			const bgColorsYellow = ['#f59e0b','#fbbf24','#fcd34d','#fde68a'];
			const bgColorsGreen = ['#16a34a','#22c55e','#4ade80','#86efac','#bbf7d0','#14b8a6','#2dd4bf','#5eead4','#99f6e4'];
			const bgColorGray = '#d1d5db';
			const data = {
				labels: [],
				datasets: [{
					data: [],
					backgroundColor: [],
					hoverOffset: 4
				}]
			};
			// loop over the activities and add them to the dataset
			const ds = new Map();
			this.activities.forEach((activity) => {
				let percentage = this.percentageOfAllTimeSpent(activity.totalTimeSpent);
				// combine all activities with less than 5% of the total time spent into one 'Others' category
				if (percentage < 5) {
					if(ds.has('Others')) {
						ds.set('Others', ds.get('Others') + percentage);
					} else {
						ds.set('Others', percentage);
					}
				} else {
					ds.set(activity.activity.name, percentage);
				}
			});
			data.labels = Array.from(ds.keys());
			data.datasets[0].data = Array.from(ds.values());
			// loop over the data and assign a color to each activity based on the percentage of time spent
			data.datasets[0].backgroundColor = data.labels.map((label) => {
				if (label === 'Others') {
					return bgColorGray;
				} else {
					let percentage = ds.get(label);
					if (percentage < 15) {
						return bgColorsGreen.shift();
					} else if (percentage < 30) {
						return bgColorsYellow.shift();
					} else {
						return bgColorsRed.shift();
					}
				}
			});
			console.log(data);

			return data;
		}
	},

	async mounted() {
		this.reload();
	},

	watch: {
		filter() {
			this.reload();
		}
		/*,

		pupil() {
			this.reload();
		}*/
	},

	methods: {

		async reload() {

			this.loading = true;
			await this.insightsStore.loadActivities(this.filter);
			this.updateSorting();
			this.loading = false;
		},

		calcBarWidth(percentage) {
			if (this.showAsAvgTime) {
				return (percentage / 100) * this.maxBarWidth / (this.maxPercentageOfAverages() / 100);
			} else {
				return (percentage / 100) * this.maxBarWidth / (this.percentageOfAllTimeSpent(this.maxTotalTimeSpentInActivity) / 100);
			}
		},

		readableDuration(seconds, narrow = false) {
			let duration = Duration.fromObject({ seconds: seconds });
			let roundedDuration = duration.mapUnits(x => Math.round(x / 60) * 60);
			if(seconds > 3600) {
				roundedDuration = roundedDuration.shiftTo('hours', 'minutes');
			} else {
				roundedDuration = roundedDuration.shiftTo('minutes');
			}
			return roundedDuration.toHuman({
				unitDisplay: narrow? 'narrow' : 'short',
			});
		},

		activityAverage(activity) {
			return activity.totalTimeSpent / Math.max(1, activity.sessions);
		},

		percentageOfAllTimeSpent(seconds) {
			return Math.round(100 * seconds / this.totalTimeSpent);
		},

		percentageOfAverages(activity) {
			let average = activity.totalTimeSpent / Math.max(1, activity.sessions);
			return Math.round(100 * average / this.totalAverages);
		},

		maxPercentageOfAverages() {
			return Math.round(100 * this.maxAverageDurationInActivity / this.totalAverages);
		},

		onAverageToggleChange() {
			this.updateSorting();
		},

		updateSorting() {
			if (this.showAsAvgTime) {
				this.sortOnAverages();
			} else {
				this.sortOnAbsolutes();
			}
		},

		sortOnAverages() {
			this.activities.sort((a, b) => {
				let aAvg = this.activityAverage(a);
				let bAvg = this.activityAverage(b);
				return bAvg - aAvg;
			});
		},

		sortOnAbsolutes() {
			this.activities.sort((a, b) => {
				return b.totalTimeSpent - a.totalTimeSpent;
			});
		}
	}

}
</script>
