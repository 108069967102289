<template>

	<!--
	<div class="bg-green-50 bg-green-100 bg-green-150 bg-green-200 bg-green-250 bg-green-300 bg-green-350 bg-green-400 bg-green-450 bg-green-500 bg-green-550 bg-green-600 bg-green-650 bg-green-700 bg-green-750 bg-green-800 bg-green-850 bg-green-900 bg-green-950 bg-green-1000" />
	-->

	<div v-if="loading">
		<div class="flex justify-center">
			<span class="loading loading-spinner loading-md"></span>
		</div>
	</div>

	<div v-if="!loading && colPupils.length === 1">{{ $t('Insufficient pupils to generate this report' )}}</div>

	<template v-if="!loading && colPupils.length > 1">

		<div class="alert alert-info mb-8">
			{{ $t('#social-matrix-info') }}
		</div>

		<div class="grid mb-10 mx-auto" :style="{
			maxWidth: (4 * colPupils.length) + 'rem',
			gridTemplateColumns: 'repeat(' + (colPupils.length + 4) + ', minmax(0, 1fr)',
			gridAutoRows: 'minmax(40px, auto)',
		}">

			<div class="col-span-4"></div>

			<div
				v-for="(pupil, index) in colPupils"
				class="leading-none pl-3 border-r border-gray-300 py-2 text-right"
				style="writing-mode: vertical-lr;"
				:class="[
					!pupil.active ? 'line-through text-gray-400': '',
				]"
			>{{ pupil.getFullName() }}
			</div>

			<template v-for="(pupilA, rowIndex) in rowPupils">

				<div class="col-span-4 border-b border-r border-gray-300 p-2 min-w-content truncate">
					{{ pupilA.getFullName() }}
				</div>

				<template v-for="pupilB in colPupils">

					<div class="border-b border-r border-gray-300 p-1"
						 :class="[
							 pupilA.id === pupilB.id ? 'bg-gray-100':'bg-white',
							 rowIndex === 0 ? 'border-t border-gray-300': ''
						 ]"
					>

						<template v-if="pupilA.id !== pupilB.id">

							<div :title="
								percentage(insightsStore.getSocialMatrixValue(pupilA, pupilB).percentage) +
								' (' + duration(insightsStore.getSocialMatrixValue(pupilA, pupilB).duration) + ')'
							" class="w-full min-h-full"
								 :class="[
									 'bg-green-' + colorNumber(insightsStore.getSocialMatrixValue(pupilA, pupilB))
								 ]"
							></div>

						</template>

					</div>

				</template>


			</template>


		</div>

	</template>


</template>

<script lang="ts">
import { mapState, mapStores } from "pinia";
import { InsightsFilter, PupilInteraction, useInsightsStore } from "@/stores/Insights.store";
import { usePupilsStore } from "@/stores/Pupils.store";
import { Pupil } from "@/models/Pupil.model";

export default {

	props: {

		filter: {
			type: InsightsFilter,
			required: true
		},

		pupil: {
			type: Pupil,
			required: false
		}

	},

	computed: {
		...mapStores(useInsightsStore, usePupilsStore),
		...mapState(useInsightsStore, [
			'activities',
			'maxTotalTimeSpentInActivity',
			'totalTimeSpent',
			'socialMatrixRowPupils',
			'socialMatrixColPupils'
		]),

		rowPupils() {
			if (this.pupil) {
				return [this.pupil];
			}

			return this.socialMatrixRowPupils;
		},

		colPupils() {
			if (this.pupil) {
				return this.socialMatrixColPupils.filter(pupil => pupil.id !== this.pupil.id);
			}

			return this.socialMatrixColPupils;
		}
	},

	data() {
		return {
			maxBarWidth: 300,
			loading: true
		}
	},

	async mounted() {
		await this.reload();
	},

	watch: {
		filter() {
			this.reload();
		}
	},

	methods: {

		async reload() {
			this.loading = true;
			await this.insightsStore.loadSocialMatrix(this.filter);

			this.loading = false;
		},

		duration(duration: Duration) {
			return duration.toFormat("hh'" + this.$t('u') + "'mm'" + this.$t('m') + "'")
		},

		percentage(percentage: number) {
			return Math.round(percentage) + '%';
		},

		colorNumber(pupilInteraction: PupilInteraction) {

			if (this.insightsStore.socialMaxTimeSpent.equals(this.insightsStore.socialMinTimeSpent)) {
				return 0
			}

			const colorInterval = this.insightsStore.socialMaxTimeSpent
				.minus(this.insightsStore.socialMinTimeSpent)
				.toMillis() / 19;

			return Math.ceil(
				(pupilInteraction.duration.minus(this.insightsStore.socialMinTimeSpent).toMillis()) / colorInterval
			) * 50;
		}

	}

}
</script>
