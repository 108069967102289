<template>
	<TheStdLayout :title="$t('Insights')">

		<div class="px-4 pb-6 mx-auto sm:px-6 md:px-8">

			<template v-if="loaded">

				<div class="sticky z-10 top-0 pt-6 bg-base">
					<InsightsFilterComponent
						v-model="filter"
						:showPupilSelector="true"
						:showDateRangeSelector="true"
						:dateRangeSelectorReadOnly="currentReport === 'social'"
						@change="applyFilter"
					/>

					<div class="border-b mb-8">
						<Tabs
							:value="currentReport"
							:options="[
								{ value: 'activity', label: this.$tc('Activity',2) },
								{ value: 'feedback', label: this.$t('Feedback') },
								{ value: 'social', label: this.$t('Social interaction') },
							]"
							@change="changeReport"
							class="text-md">
						</Tabs>
					</div>
				</div>

				<div class="">
					<template v-if="loaded">

						<template v-if="currentReport === 'activity'">
							<!-- the below pupil property should be obsolete once 'activity' report supports 'all pupils' query -->
							<InsightsActivities :pupil="pupil" :filter="filter"></InsightsActivities>
						</template>

						<template v-if="currentReport === 'feedback'">
							<InsightsFeedback :filter="filter" :withNameColumn="true"></InsightsFeedback>
						</template>

						<template v-if="currentReport === 'social'">
							<InsightsSocial :filter="filter" :pupil="pupil"></InsightsSocial>
						</template>

					</template>
				</div>

			</template>

		</div>

	</TheStdLayout>
</template>

<script lang="ts">
import {mapState, mapStores} from "pinia";
import {InsightsFilter, useInsightsStore} from "@/stores/Insights.store";
import { DateTime, Interval } from "luxon";
import { usePupilsStore } from "@/stores/Pupils.store";
import InsightsActivities from "@/components/InsightsActivities.vue";
import InsightsFilterComponent from "@/components/InsightsFilter.vue";
import InsightsFeedback from "@/components/InsightsFeedback.vue";
import InsightsSocial from "@/components/InsightsSocial.vue";
import Tabs from "@/components/ui/Tabs.vue";


export default {
	components: {
		InsightsFeedback,
		InsightsFilterComponent,
		InsightsActivities,
		InsightsSocial,
		Tabs,
	},

	data() {
		return {
			pupil: null,
			filter: null,
			loaded: false,
			currentReport: 'activity',
		}
	},

	computed: {
		...mapStores(usePupilsStore),
		...mapState(useInsightsStore, [ 'activities', 'maxTotalTimeSpentInActivity' ]),
	},

	async mounted() {
		this.initializeFilter();

		this.currentReport = this.$route.meta.report || sessionStorage.getItem("insights.report") || 'feedback';
		this.loaded = true;
	},

	methods: {
		initializeFilter() {
			this.filter = new InsightsFilter();
			// this.filter.pupilId = this.pupil ? this.pupil.id : null;
			this.filter.pupilId = null;
			this.filter.dateRange = this.retrieveDateRange();
		},

		applyFilter(filter: InsightsFilter) {
			if(this.currentReport !== 'social') {
				this.storeDateRange();
			}

			if (filter.pupilId) {
				this.pupil = this.pupilsStore.findById(filter.pupilId);
			} else {
				this.pupil = null;
			}
		},

		changeReport(report: string) {

			// Has report changed?
			if (this.currentReport !== report) {

				if (report === 'social') {
					// last 31 days
					this.filter.dateRange = Interval.fromDateTimes(
						DateTime.now().endOf('day').minus({ days: 31 }),
						DateTime.now().endOf('day')
					);

				} else if (this.currentReport === 'social') {
					this.filter.dateRange = this.retrieveDateRange();
				}

				// Change route
				this.$router.push({ name: 'classroom.insights.' + report });
			}

			this.currentReport = report;
			sessionStorage.setItem("insights.report", report);
		},

		storeDateRange() {
			sessionStorage.setItem("insights.dateRange", JSON.stringify(this.filter.dateRange.toISO()));
		},

		retrieveDateRange() {
			const intervalStr = sessionStorage.getItem("insights.dateRange");
			if (intervalStr) {
				return Interval.fromISO(JSON.parse(intervalStr));
			} else {
				return Interval.fromDateTimes(DateTime.now().startOf('week'), DateTime.now().endOf('week'));
			}
		},
	}

}
</script>
