import Home from "@/components/Home.vue";
import Activities from "@/components/Activities.vue";
import Agenda from "@/components/Agenda.vue";
import Classrooms from "@/components/Classrooms.vue";
import IconLibraries from "@/components/IconLibraries.vue";
import IconLibrary from "@/components/IconLibrary.vue";
import Pupils from "@/components/Pupils.vue";
import Users from "@/components/Users.vue";
import ActivityBoard from "@/components/ActivityBoard.vue";
import ActivityBoardStartScreen from "@/components/ActivityBoardStartScreen.vue";
import ActivityBoardSetupInfo from "@/components/ActivityBoardSetupInfo.vue";
import Organisations from "@/components/Organisations.vue";
import Observations from "@/components/Observations.vue";
import Insights from "@/components/Insights.vue";
import PageNotFound from "@/components/PageNotFound.vue";
import Preferences from "@/components/Preferences.vue";
import Support from "@/components/Support.vue";
import NetworkError from "@/components/NetworkError.vue";
import SelectOrganisation from "@/components/SelectOrganisation.vue";
import Goals from "@/components/Goals.vue";
import GoalsImport from "@/components/GoalsImport.vue";
import GoalsTableImport from "@/components/GoalsTableImport.vue";
import GoalsTableToJson from "@/components/GoalsTableToJson.vue";
import LessonPlanLibrary from "@/components/LessonPlanLibrary.vue";
import ReflectionBoard from "@/components/ReflectionBoard.vue";

export const routes = [
	{
		name: 'home',
		path: '/',
		component: Home,
	},
	{
		name: 'organisation.select',
		path: '/select-organisation',
		component: SelectOrganisation,
	},
	{
		name: 'user.list',
		path: '/users',
		component: Users,
		meta: {
			permissions: 'admin'
		}
	},
	{
		name: 'schools.list',
		path: '/schools',
		component: Organisations,
		meta: {
			permissions: 'superadmin'
		}
	},
	{
		name: 'icon-library.list',
		path: '/icon-libraries',
		component: IconLibraries,
		props: {
			scope: 'global'
		},
		meta: {
			permissions: 'admin'
		}
	},
	{
		name: 'icon-library.view',
		path: '/icon-libraries/:libraryId',
		component: IconLibrary,
		props: {
			scope: 'global'
		},
		meta: {
			permissions: 'admin'
		}
	},
	{
		name: 'classroom.list',
		path: '/classrooms',
		component: Classrooms,
		meta: {
			permissions: 'admin,not-superadmin'
		}
	},
    {
		name: 'goals.list',
		path: '/goals',
		component: Goals,
		meta: {
			permissions: 'teacher'
		}
	},
    {
		name: 'goals.import',
		path: '/goals/import',
		component: GoalsImport,
		meta: {
			permissions: 'admin'
		}
	},
    {
		name: 'goals.table-import',
		path: '/goals/table-import',
		component: GoalsTableImport,
		meta: {
			permissions: 'admin'
		}
	},
    {
		name: 'goals.table-to-json',
		path: '/goals/table-to-json',
		component: GoalsTableToJson,
		meta: {
			permissions: 'admin'
		}
	},

	{
		name: 'pupil.list',
		path: '/pupils',
		component: Pupils,
		props: {
			scope: 'organisation'
		},
		meta: {
			permissions: 'admin,not-superadmin'
		}
	},

	{
		name: 'classroom.pupil.list',
		path: '/classrooms/:classroomId/pupils',
		component: Pupils,
		props: {
			scope: 'classroom'
		},
		meta: {
			permissions: 'teacher'
		}
	},
	{
		name: 'classroom.activity.list',
		path: '/classrooms/:classroomId/activities',
		component: Activities,
		meta: {
			permissions: 'teacher'
		}
	},
	{
		name: 'lesson-plan-library.list',
		path: '/lesson-plan-library',
		component: LessonPlanLibrary,
		meta: {
			permissions: 'teacher'
		}
	},
	{
		name: 'classroom.icon-library.list',
		path: '/classrooms/:classroomId/icon-libraries',
		component: IconLibraries,
		props: {
			scope: 'classroom'
		},
		meta: {
			permissions: 'teacher'
		}
	},
	{
		name: 'classroom.icon-library.view',
		path: '/classrooms/:classroomId/icon-libraries/:libraryId',
		component: IconLibrary,
		props: {
			scope: 'classroom'
		},
		meta: {
			permissions: 'teacher'
		}
	},
	{
		name: 'classroom.agenda',
		path: '/classrooms/:classroomId/agenda',
		component: Agenda,
		meta: {
			permissions: 'teacher'
		}
	},
	{
		name: 'classroom.observation.list',
		path: '/classrooms/:classroomId/observations',
		component: Observations,
		meta: {
			permissions: 'teacher'
		}
	},
	{
		name: 'classroom.insights',
		path: '/classrooms/:classroomId/insights',
		component: Insights,
		meta: {
			permissions: 'teacher'
		}
	},

	{
		name: 'classroom.insights.activity',
		path: '/classrooms/:classroomId/insights/activity',
		component: Insights,
		meta: {
			permissions: 'teacher',
			report: 'activity'
		}
	},

	{
		name: 'classroom.insights.feedback',
		path: '/classrooms/:classroomId/insights/feedback',
		component: Insights,
		meta: {
			permissions: 'teacher',
			report: 'feedback'
		}
	},

	{
		name: 'classroom.insights.social',
		path: '/classrooms/:classroomId/insights/social',
		component: Insights,
		meta: {
			permissions: 'teacher',
			report: 'social'
		}
	},

	// {
	// 	name: 'agenda',
	// 	path: '/',
	// 	component: Agenda
	// },
	{
		name: 'activity-board',
		path: '/activity-board',
		component: ActivityBoard,
		meta: {
			activityBoardConnect: true,
			pusherConnect: true,
		},
	},
	{
		name: 'activity-board-select',
		path: '/board',
		component: ActivityBoardStartScreen,
		meta: {
			guestAccess: true,
			pusherConnect: true,
		},
	},

	{
		name: 'reflection-board',
		path: '/reflection-board',
		component: ReflectionBoard,
		meta: {
			activityBoardConnect: true,
			pusherConnect: true,
		}
	},

	{
		name: 'classroom.activity-board-setup-info',
		path: '/classrooms/:classroomId/activity-board-setup-info',
		component: ActivityBoardSetupInfo,
	},

	{
		name: 'user.preferences',
		path: '/preferences',
		component: Preferences,
	},
	{
		name: 'support',
		path: '/support',
		component: Support,
		meta: {
			guestAccess: true
		},
	},

	{
		name: 'network-error',
		path: '/network-error',
		component: NetworkError,
		meta: {
			guestAccess: true
		},
	},

	{
		name: 'not-found',
		path: '/:pathMatch(.*)*',
		component: PageNotFound,
	},
];
